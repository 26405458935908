import { render, staticRenderFns } from "./NiceToKnow.vue?vue&type=template&id=14637bef&scoped=true&"
import script from "./NiceToKnow.vue?vue&type=script&lang=js&"
export * from "./NiceToKnow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14637bef",
  null
  
)

export default component.exports