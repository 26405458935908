<template>
  <div id="background" :style="{ backgroundImage: 'url(' + imagepath() + ')'  }">
    <Header color="primary"/>

    <div class="wrapper">
      <div id="content">
        <v-container style="background-color: rgba(241,241,241,0.8);">
          <v-row align="center" justify="center">
            <v-col class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '80px' }" xl="8" lg="10" md="12" sm="12"  xs="12">
              Nice to Know
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col align="left" class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '50px' }" xl="8" lg="10" md="12" sm="12"  xs="12">
              Kleiderordnung
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col xl="8" lg="10" md="12" sm="12" >
              <v-card class="card" outlined  color="transparent" v-if="!this.$vuetify.breakpoint.xsOnly">
                <v-list-item style="font-family: Raleway">
                  <v-list-item-avatar tile size="300" color="blue">
                    <v-img
                        width="300px"
                        :src="require('../../assets/nicetoknow/clothing.jpg')"
                        :lazy-src="require('../../assets/nicetoknow/clothing.jpg')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div :style="{ fontFamily: 'Raleway', whiteSpace: 'pre-line', fontSize: getFontSize() }">
                      {{ text }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <v-card class="card" outlined color="transparent" v-if="this.$vuetify.breakpoint.xsOnly" align="center">
                <v-img
                    square
                    max-width="300px"
                    :src="require('../../assets/nicetoknow/clothing.jpg')"
                    :lazy-src="require('../../assets/nicetoknow/clothing.jpg')"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <v-card-text class="text--primary">
                  <div style="font-family: Raleway; text-align: justify;">
                    {{ text }}
                  </div>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="this.$vuetify.breakpoint.xsOnly">
            <v-col align="left" class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '50px' }" xl="8" lg="10" md="12" sm="12"  xs="12">
              Wunschliste
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="!this.$vuetify.breakpoint.xsOnly">
            <v-col align="right" class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '50px' }" xl="8" lg="10" md="12" sm="12"  xs="12">
              Wunschliste
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col xl="8" lg="10" md="12" sm="12" xs="12">
              <v-card class="card" outlined  color="transparent" v-if="!this.$vuetify.breakpoint.xsOnly">
                <v-list-item style="font-family: Raleway">

                  <v-list-item-content>
                    <div :style="{ fontFamily: 'Raleway', whiteSpace: 'pre-line', fontSize: getFontSize() }" v-html="textWunschliste">
                    </div>
                  </v-list-item-content>
                  <v-list-item-avatar tile size="300" color="blue">
                    <v-img
                        width="300px"
                        :src="require('../../assets/nicetoknow/presents.png')"
                        :lazy-src="require('../../assets/nicetoknow/presents.png')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
              <v-card class="card" outlined color="transparent" v-if="this.$vuetify.breakpoint.xsOnly" align="center">
                <v-img
                    square
                    max-width="300px"
                    :src="require('../../assets/nicetoknow/presents.png')"
                    :lazy-src="require('../../assets/nicetoknow/presents.png')"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <v-card-text class="text--primary">
                  <div style="font-family: Raleway; text-align: justify; " v-html="textWunschliste">
                  </div>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="this.$vuetify.breakpoint.xsOnly">
            <v-col align="left" class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '50px' }" xl="8" lg="10" md="12" sm="12"  xs="12">
              Trauzeugen
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="!this.$vuetify.breakpoint.xsOnly">
            <v-col align="left" class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '50px' }" xl="8" lg="10" md="12" sm="12"  xs="12">
              Trauzeugen
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col align="left" :style="{ fontFamily: 'Raleway'}" xl="8" lg="10" md="12" sm="12"  xs="12">
              Wer sich gerne im Voraus mit unseren Trauzeugen absprechen möchte darf dies gerne tun.
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col xl="4" lg="5" md="6" sm="7" xs="11">
              <v-card color="transparent" elevation="0">
                <v-card-title :style="{ fontFamily: 'Raleway' }">
                  Simona Badilatti
                </v-card-title>
                <v-card-subtitle class="text-justify" :style="{ fontFamily: 'Raleway' }">
                  Trauzeugin Sabrina
                </v-card-subtitle>
                <div class="text-center" style="margin: auto">
                  <v-img
                      :src="require('../../assets/nicetoknow/simona.jpg')"
                      :lazy-src="require('../../assets/nicetoknow/simona.jpg')"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <v-card-text class="text-justify" v-html="textSimona" style="font-family: Raleway;">

                </v-card-text>
              </v-card>
            </v-col>
            <v-col xl="4" lg="5" md="6" sm="7" xs="11">
              <v-card color="transparent" elevation="0">
                <v-card-title :style="{ fontFamily: 'Raleway' }">
                  Mirco Huber
                </v-card-title>
                <v-card-subtitle class="text-justify" :style="{ fontFamily: 'Raleway' }">
                  Trauzeuge Silas
                </v-card-subtitle>
                <div class="text-center" style="margin: auto">
                  <v-img
                      :src="require('../../assets/nicetoknow/mirco.png')"
                      :lazy-src="require('../../assets/nicetoknow/mirco.png')"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <v-card-text class="text-justify" v-html="textMirco" style="font-family: Raleway;">
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-container>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import sharedfunctions from "../../sharedfunctions";
import {mdiMinus} from "@mdi/js";

export default {
  name: "NiceToKnow",
  components: {Footer, Header},
  data: () => ({
    icons: {
      mdiMinus
    },
    text: 'Wir haben keine spezifische Kleiderordnung würden uns aber über winterliche, elegante Kleidung freuen!',
    textWunschliste:"<i>Nicht des Schenkens wegen seid Ihr geladen.<br/>Kommt mit guter Laune und viel Zeit,<br/>dann macht Ihr uns die größte Freud.<br/>Wollt Ihr uns trotzdem etwas schenken,<br/>könnt Ihr an unser Sparschwein denken!</i><br/><br/><br/><br/>" +
        "Unser Sparschwein werden wir in nächster Zeit vor allem für die Erstausstattung für unser Kind bemühen. Auch gehen wir direkt nach der Hochzeit noch ein paar Tage unsere Zweisamkeit in einem Wellnesshotel geniessen.",

    textMirco:"Wohnort: Winterthur<br/>Kontakt: <a href='mailto:hubermir@outlook.com'>hubermir@outlook.com</a><br/><br/>" +
        "Mirco war der langjährige Study-Buddy von Silas. Viele Stunden haben sie beim gemeinsamen studieren verbracht. Durch zwei Bachelorarbeiten haben sie sich zusammen gekämpft, was die beiden einiges über einander lehrte. So fiel die Wahl des Trauzeugen für Silas nicht allzu schwer und Mirco fühlt sich geehrt dieses \"Amt\" übernehmen zu dürfen.",

    textSimona:"Wohnort: Chur<br/>Kontakt: <a href='mailto:simona.badilatti@hispeed.ch'>simona.badilatti@hispeed.ch</a><br/><br/>" +
        "Simona ist die (kleine) Schwester von Sabrina. Schon bevor Silas überhaupt ins Leben von Sabrina trat, wurde beschlossen, dass Simona eines Tages Trauzeugin von Sabrina wird. Jetzt ist es endlich soweit und Simona freut sich bereits über alle Pflichten und Rechte ihres \"Amtes\".<br/><br/>"

  }),
  methods: {
    getFontSize(isTitle = false) {
      if (this.isMobile()) {
        return isTitle ? 'medium' : 'small';
      } else {
        return isTitle ? 'x-large' : 'large';
      }
    },
    setBackgroundColor() {
      if (this.isMobile()) {
        return 'rgba(241,243,240,0.8)'
      } else {
        return 'rgba(241,243,240,0.8)'
      }
    },
    imagepath() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return require("../../assets/backgrounds/background.jpg");
        case 'sm':
          return require("../../assets/backgrounds/background.jpg");
        case 'md':
          return require("../../assets/backgrounds/pexels-oscar-helgstrand-5957574.jpg");
        case 'lg':
          return require("../../assets/backgrounds/pexels-oscar-helgstrand-5957574.jpg");
        case 'xl':
          return require("../../assets/backgrounds/pexels-oscar-helgstrand-5957574.jpg");
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    initColorScheme() {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    },
    // eslint-disable-next-line no-unused-vars
    myEventHandler(e) {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    }
  },
  beforeMount(){
    this.$vuetify.theme.dark = false;
  },
  created(){
    window.addEventListener("resize", this.myEventHandler);
    this.isMobile = sharedfunctions.isMobile;
    this.updateDarkMode = sharedfunctions.updateDarkMode;
    this.initColorScheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style scoped>

</style>
